














































































import { Component, Vue } from "vue-property-decorator";
import draggable from "vuedraggable";
import { State, namespace } from "vuex-class";

import ImportantCell from "./ImportantCell.vue";
import ImportantLocation from "./ImportantLocation.vue";
import ImportantCheck from "./ImportantCheck.vue";
import {
  HintSetting,
  Items,
  Location,
  State as TrackerState,
} from "@/store/tracker_important/state";

const tracker = namespace("tracker_important");
const settings = namespace("settings");

@Component({
  components: {
    ImportantCell,
    ImportantLocation,
    ImportantCheck,
    draggable,
  },
})
export default class ImportantGrid extends Vue {
  @State("tracker_important") state!: TrackerState;
  @tracker.State items!: Items;
  @tracker.State hintSettings!: { [key: string]: HintSetting };
  @tracker.State("checks") numChecks!: number;
  @tracker.State hintMessage!: string;
  @tracker.State hintsLoaded!: boolean;
  @tracker.State foundChecks!: { [key: string]: string[] };
  @tracker.State selectedLocation!: string;

  @tracker.Action foundCheck!: (payload: {
    check: string;
    location: string;
    shift: boolean;
  }) => void;

  @tracker.Action undoCheck!: (payload: {
    check: string;
    location: string;
    shift: boolean;
  }) => void;

  @tracker.Mutation incrementChecks!: (offset: number) => void;
  @tracker.Mutation selectLocation!: (location: string) => void;

  @settings.State("important") settings!: { [key: string]: any };
  @settings.State disableShadows!: boolean;

  dragging = false;

  get hintsAtBottom(): boolean {
    return this.settings.hintsAtBottom;
  }

  get totalChecks(): number {
    let total = 51;
    Object.values(this.hintSettings).forEach((s: HintSetting) => {
      if (s.check && !s.enabled) {
        total -= s.value ?? 1;
      }
    });

    return total;
  }

  get gridStyle(): object {
    const settings = this.$store.state.settings;

    const style: { [key: string]: string } = {
      backgroundColor: settings.bgColor,
      width: this.settings.width,
    };

    const bgImg = settings.bgImg;
    const darken = "rgba(0, 0, 0, .6)";
    if (bgImg) style.backgroundImage = `linear-gradient(${darken}, ${darken}), url(${bgImg})`;

    if (this.$route.query.footer === "0") {
      style.borderRadius = "4px";
      style.margin = "0";
    }

    return style;
  }

  get worldRowStyle(): object {
    return {
      height: this.settings.worldSize || "65px",
      padding: `${this.settings.worldVerticalPadding || "5px"} 0`,
    };
  }

  get hintedChecks(): [number, number] {
    let hinted = 0;
    let total = 0;

    this.items.locations
      .flat()
      .map(l => this.items.all[l] as Location)
      .filter(l => l.totalChecks !== -1)
      .forEach(l => {
        hinted += l.checks;
        total += l.totalChecks;
      });

    return [hinted, total];
  }

  numChecksStyle(num: number, total: number): object {
    // avoid zero division error by just using 1 as a fallback
    total = total || 1;
    // something's definitely not right if num is greater than total but keep a maximum anyway
    num = Math.min(num, total);

    return {
      flex: 1,
      fontWeight: "bold",
      alignSelf: "center",
      color: `hsl(${160 - (num / total) * 160}, 100%, 75%)`,
    };
  }

  showWorld(location: string): boolean {
    const hintKey = this.items.all[location].setting;
    if (!hintKey) {
      return true;
    }

    if (!this.hintsLoaded && location === "worlds/atlantica") {
      return this.settings.atlantica;
    }

    const hint = this.hintSettings[hintKey];
    return hint.enabled || hint.show;
  }

  showReports(location: string): boolean {
    if (!this.$store.state.tracker_important.hints.length) {
      // hints haven't been loaded, do nothing
      return false;
    }

    // show the Reports view component knows which location to check reports for
    this.$store.commit("tracker_important/setLocation", location);
    this.$router.push("reports");

    return true;
  }

  add(location: string, check: string) {
    // Show menu for reports/hints
    // if showReports returns false, then hints aren't loaded and we just treat this as a regular
    // check
    if (check === "other/secret_reports" && this.showReports(location)) {
      return;
    }

    this.$store.dispatch("tracker_important/foundCheck", { check, location });
  }

  removeCheck(location: string) {
    const items = this.foundChecks[location];
    if (items.length === 0) return;

    this.$store.dispatch("tracker_important/undoCheck", { check: items[0], location });
  }
}
