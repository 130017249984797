




































import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

import type { Item } from "@/store/types";

const tracker = namespace("tracker_important");

@Component
export default class ImportantCell extends Vue {
  @Prop(String) file!: string;
  @Prop(Number) hinted!: number;
  @Prop(String) width!: string;

  @tracker.Action secondary!: (cell: string, offset: number) => void;

  cls: string = this.cell.cls ?? "";

  get cell(): Item {
    return this.$store.getters["tracker_important/cell"](this.file);
  }

  get secondaryFile(): string {
    return this.$store.getters["tracker_important/secondary"](this.file);
  }

  get secondaryNumber(): string {
    return this.$store.getters["tracker_important/secondaryNumber"](this.file);
  }

  styledIcon(file: string): string {
    const cell = this.$store.getters["tracker_important/cell"](file);
    const style = this.$store.state.settings.iconStyles[cell.category]?.value;

    if (style === cell.categoryExclude) {
      return `default/${file}`;
    }

    switch (style) {
      case "Minimal":
        return `minimal/${file}`;
      case "Classic":
        return `legacy/${file}`;
      default:
        return `default/${file}`;
    }
  }
}
