





import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ChecksPopup extends Vue {
  @Prop(String) location!: string;

  get checks(): string[] {
    return this.$store.state.tracker_important.foundChecks[this.location];
  }

  styledIcon(file: string): string {
    const cell = this.$store.getters["tracker_important/cell"](file);
    const style = this.$store.state.settings.iconStyles[cell.category]?.value;

    if (style === cell.categoryExclude) {
      return `default/${file}`;
    }

    switch (style) {
      case "Minimal":
        return `minimal/${file}`;
      case "Classic":
        return `legacy/${file}`;
      default:
        return `default/${file}`;
    }
  }
}
